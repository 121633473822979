import React from 'react';
import styled from "styled-components";
import { device, Imgix } from "../../utils";
import MagicLink from "../link";

const Grid = styled.div`
  display: grid;
  column-gap: 2px;
  row-gap: 20px;
  grid-gap: 20px 2px;
  grid-template-columns: 1fr 1fr;
  margin-top: 20px;
  margin-bottom: 20px;

  @media ${device.tablet} {
    grid-gap: 30px 2px;
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media ${device.laptop} {
    grid-gap: 20px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  a {
    display: block;
    overflow: hidden;
    backface-visibility: hidden;
    transform: translate3d(0,0,0);
    backface-visibility: hidden;

    .image {
      transform: scale(1) translateZ(0px);
      transform-style: preserve-3d;
      transform-origin: bottom left;
      transition: transform 300ms ease-out;
      backface-visibility: hidden;
      will-change: transform;
      overflow: visible !important;
    }

    @media(hover: hover) {
      &:hover {
        .image {
          transform: scale(1.03) translateZ(1px);
        }
      }
    }
  }
`;

const imgResponsive = {
  xxs: 6,
  md: 4,
  lg: 3,
  contained: true,
};

const TutoGrid = ({ className, items = [] }, preloadHead = false) => {
  return (
    <Grid className={className}>
      {items.map(({ id, link, image }, idx) => (
        <MagicLink key={id} {...link}>
          <Imgix
            {...image}
            alt={image.alt || "Ipone"}
            sizes={imgResponsive}
            loading={preloadHead && idx < 4 ? 'eager' : 'lazy'}
            fadeIn={preloadHead && idx < 4 ? false : true}
            preloadHead={preloadHead && idx < 4}
            imgStyle={{
              objectFit: 'contain',
              objectPosition: 'bottom center',
            }}
          />
        </MagicLink>
      ))}
    </Grid>
  );
};

export default TutoGrid;