import styled from 'styled-components';

import { device } from '../../utils';

export const Wrapper = styled.article`
  width: 100%;
  display: block;
  background-color: #f2f3f5;

  --padding-small: 30px;
  --padding-large: 60px;

  @media ${device.tablet} {
    .container {
      padding-left: clamp(20px, 5%, 90px);
      padding-right: clamp(20px, 5%, 90px);
    }
  }
`;

export const Slices = styled.div`
  & > :last-child {
    margin-bottom: 0;
  }
`;
