import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';
import Carousel from '../../components/slices/Carousel';
// import { Imgix } from '../../utils';

const Wrapper = styled.div`
  background-color: #fff;

  &.pt-small {
    padding-top: var(--padding-small);
  }

  &.pb-small {
    padding-bottom: var(--padding-small);
  }

  &.pt-large {
    padding-top: var(--padding-large);
  }

  &.pb-large {
    padding-bottom: var(--padding-large);
  }
  .carousel {
    margin: 0;
    
    .container {
      padding-left: 0;
      padding-right: 0;
      margin-top: 0;
    }

    .slider-container {
      padding-left: -20px;
      padding-right: -20px;
    }
  }
`;

const Slider = ({
  paddingTop,
  paddingBottom,
  backgroundColor = '#FFF',
  items = []
}) => {
  console.log('*** items', items);
  return (
    <Wrapper
      className={classNames(
        'container',
        `pt-${paddingTop}`,
        `pb-${paddingBottom}`
      )}
      style={{
        backgroundColor,
      }}
    >
      <Carousel
        className="carousel"
        items={items}
      />
    </Wrapper>
  );
};

export default Slider;
