import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';
import HtmlRenderer from '../../components/common/Html';
// import { Imgix } from '../../utils';
import { device } from '../../utils';

const Wrapper = styled.div`
  text-align: center;
  background-color: #fff;

  &.pt-small {
    padding-top: var(--padding-small);
  }

  &.pb-small {
    padding-bottom: var(--padding-small);
  }

  &.pt-large {
    padding-top: var(--padding-large);
  }

  &.pb-large {
    padding-bottom: var(--padding-large);
  }

  h1 {
    font-size: 30px;
    font-weight: 900;
  }

  h2 {
    font-size: 24px;
  }

  h3 {
    font-size: 22px;
    font-weight: normal;
  }

  h4 {
    font-size: 18px;
    font-weight: normal;
  }

  p {
    font-size: 15px;
  }

  @media ${device.laptop} {
    h1 {
      font-size: 45px;
    }

    h2 {
      font-size: 30px;
    }

    h3 {
      font-size: 22px;
    }

    h4 {
      font-size: 18px;
    }

    p {
      font-size: 16px;
    }
  }

  .gatsby-image-wrapper {
    max-width: 100% !important;
  }

  ul {
    li {
      display: block;
      margin-bottom: 25px;

      strong {
        display: inline-block;
        font-size: 18px;
        margin-bottom: 5px;
      }
    }
  }

  .content {
    p + .gatsby-image-wrapper {
      margin-top: -15px;
    }
  }

  .html-renderer {
    & > :first-child {
      margin-top: 0;
    }
    & > :last-child {
      margin-bottom: 0;

      *:last-child {
        margin-bottom: 0;
      }
    }

    p > a {
      &:first-child:last-child {
        border-radius: 30px;
        background-color: #c80f05;
        padding: 7px 20px;
        transition: background-color 300ms;
        color: #FFF;

        &:before {
          display: none;
        }
      }
    }
  }

  strong {
    font-weight: bold;
  }
`;

const Content = ({
  content,
  paddingTop,
  paddingBottom,
  textColor,
  backgroundColor = '#FFF',
  maxWidth
}) => {
  console.log(maxWidth);
  return (
    <Wrapper
      className={classNames(
        'container',
        `pt-${paddingTop}`,
        `pb-${paddingBottom}`
      )}
      style={{
        backgroundColor,
        color: textColor,
      }}
    >
      <HtmlRenderer html={content?.html} className="html-renderer" maxWidth={maxWidth} />
    </Wrapper>
  );
};

export default Content;
