import React from 'react';
import { Icon } from '../../../components/icons';
import FillIcon from '../../../icons/rating-full.svg';
import EmptyIcon from '../../../icons/rating-empty.svg';

import { Stars } from './Rating.styles';

const RatingStars = ({ rate = 0, starWidth = 15 }) => {
  return (
    <Stars>
      <div className="base">
        {new Array(5).fill(0).map((_, idx) => (
          <Icon
            key={idx}
            src={EmptyIcon}
            width={`${starWidth}px`}
            height={`${starWidth}px`}
          />
        ))}
      </div>
      <div
        className="plain"
        style={{
          width: `${Math.round((rate * 102) / 5)}%`,
        }}
      >
        {new Array(5).fill(0).map((_, idx) => (
          <Icon
            key={idx}
            src={FillIcon}
            width={`${starWidth}px`}
            height={`${starWidth}px`}
          />
        ))}
      </div>
    </Stars>
  );
};

export default RatingStars;
