import styled from 'styled-components';
import { device } from '../../../utils';

export const Wrapper = styled.a`
  margin: 20px 0 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media ${device.laptop} {
    justify-content: flex-start;
  }
`;

export const Stars = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;

  .icon {
    margin-right: 3px;

    &:last-of-type {
      margin-right: 0;
    }
  }

  .plain {
    position: absolute;
    inset: 0;
    z-index: 1;
    overflow: hidden;
    white-space: nowrap;
  }

  .base {
    display: flex;
    flex-direction: row;
  }
`;

export const Legend = styled.div`
  font-size: 12px;
  margin-left: 15px;

  @media ${device.tablet} {
    font-size: 14px;
  }
`;
