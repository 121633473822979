import React from 'react';
import { Imgix } from '../../utils';

import { Wrapper } from './Cover.styles';

const imgResponsive = {
  xxs: 12,
  contained: true,
};

const Cover = ({ mobile, desktop, eager = false }) => {
  return (
    <Wrapper className="container">
      {mobile?.url ? (
        <Imgix
          fadeIn={!eager}
          loading={eager ? 'eager' : 'lazy'}
          sizes={imgResponsive}
          preloadHead={eager}
          alt={mobile.alt || desktop.alt}
          breakpoints={{
            0: mobile,
            760: desktop,
          }}
          noCompress
        />
      ) : (
        <Imgix
          {...desktop}
          fadeIn={!eager}
          loading={eager ? 'eager' : 'lazy'}
          sizes={imgResponsive}
          preloadHead={eager}
          alt={mobile.alt || desktop.alt}
          noCompress
        />
      )}
    </Wrapper>
  );
};

export default Cover;
