import {
  useEffect,
} from "react";
import {
  useRafState
} from "react-use";

const useIntersection = (
  ref,
  options,
  once = false
) => {
  // State and setter for storing whether element is visible
  const [intersectionObserverEntry, setIntersectionObserverEntry] =
    useRafState(null);

  useEffect(() => {
    if (ref.current && typeof IntersectionObserver === "function") {
      const handler = (entries) => {
        const current = entries[0];
        setIntersectionObserverEntry(current);

        if (current?.isIntersecting && once) {
          observer.disconnect();
        }
      };

      const observer = new IntersectionObserver(handler, options);
      observer.observe(ref.current);

      return () => {
        observer.disconnect();
      };
    }
    return () => { };
  }, [ref.current, options.threshold, options.root, options.rootMargin]);

  return intersectionObserverEntry;
};

export default useIntersection;