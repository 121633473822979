import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';
// import { Imgix } from '../../utils';

const Wrapper = styled.div`
  text-align: center;
  background-color: #fff;

  &.pt-small {
    padding-top: var(--padding-small);
  }

  &.pb-small {
    padding-bottom: var(--padding-small);
  }

  &.pt-large {
    padding-top: var(--padding-large);
  }

  &.pb-large {
    padding-bottom: var(--padding-large);
  }
`;

const Spacer = ({
  paddingTop,
  paddingBottom,
  backgroundColor = '#FFF',
}) => {
  return (
    <Wrapper
      className={classNames(
        'container',
        `pt-${paddingTop}`,
        `pb-${paddingBottom}`
      )}
      style={{
        backgroundColor,
      }}
    />
  );
};

export default Spacer;
