import React from 'react';
import { graphql } from 'gatsby';
import Seo from '../components/seo';

import { oneMatch, Product } from '../utils';
import usePreviewData from '../utils/usePreviewData';
import { trackProductList } from '../utils/gtm';
import { useLocale } from '../services/i18n/useLocale';
import LandingScreen from '../screens/landing';

const LandingPageTemplate = ({ data: rawData }) => {
  const data = usePreviewData(rawData);
  const intl = useLocale();

  const page = data?.page?.data;

  const products = (data?.products?.nodes || []).map((p) =>
    Product(p, intl.locale)
  );
  const displayedProducts = [];

  const content = (page?.body || []).map((slice) => {
    if (slice.slice_type === 'carousel') {
      return {
        ...slice,
        items: (slice.items || []).map((i) => i.image).filter((i) => i),
      };
    }

    if (slice.slice_type === 'tuto') {
      return {
        ...slice,
        items: data?.tutos?.nodes.map((page) => ({
          id: page.id,
          link: { url: page.url },
          image: page.data.thumbnail,
        })).slice(0, 8)
      };
    }

    if (slice.slice_type === 'produits') {
      return {
        ...slice,
        items: (slice.items || [])
          .map((i) => {
            const current = products.find((p) =>
              oneMatch(
                p.skus,
                i.product?.variants?.map((v) => v.sku)
              )
            );
            if (!current) {
              return null;
            }
            const result = {
              ...current,
              trackList: 'PageV2',
              trackPosition: displayedProducts.length + 1,
            };

            displayedProducts.push(result);
            return result;
          })
          .filter((p) => p),
      };
    }

    return slice;
  });

  trackProductList(
    displayedProducts.map((p) => ({
      sku: p.defaultVariant?.sku,
      name: p.title,
      price: p.defaultVariant?.price,
      variant: p.defaultVariant?.title,
      category: p.productType,
      list: p.trackList,
      position: p.trackPosition,
    }))
  );

  // const firstText = (page?.body || []).find((s) => s.slice_type === 'contenu');
  // const firstTextHtml = firstText?.primary?.content?.html;
  const newPage = {
    ...page,
    body: content
  };


  return (
    <>
      <Seo
        title={page.title}
        description={data.page?.data?.seo_description}
        image={page?.cover?.url}
      />
      <LandingScreen
        page={newPage}
      />
    </>
  );
};

export const query = graphql`
  query LandingPage(
    $prismic: ID!
    $locale: String!
    $cmsLocaleGlob: String!
    # $cmsLocale: String!,
    $fetchProducts: [String!]
  ) {
    page: prismicLandingPage(prismicId: { eq: $prismic }) {
      data {
        title
        seo_description
        body {
          ... on PrismicLandingPageDataBodyCover {
            id
            slice_label
            slice_type
            primary {
              mobile {
                alt
                dimensions {
                  height
                  width
                }
                url
              }
              desktop {
                alt
                dimensions {
                  height
                  width
                }
                url
              }
            }
          }
          ... on PrismicLandingPageDataBodyContenu {
            id
            slice_type
            primary {
              background_color
              text_color
              padding_top
              padding_bottom
              max_width
              content {
                html
              }
            }
          }
          ... on PrismicLandingPageDataBodyFeature {
            id
            primary {
              padding_top
              padding_bottom
              container_color
              background_color
              text_color
              image_left {
                alt
                dimensions {
                  height
                  width
                }
                url
              }
              section_title
              section_content {
                html
              }
              link {
                url
              }
              link_title
              image_right {
                alt
                dimensions {
                  height
                  width
                }
                url
              }
              image_right_mobile {
                alt
                dimensions {
                  height
                  width
                }
                url
              }
              reverse_desktop
            }
            slice_type
          }
          ... on PrismicLandingPageDataBodySpacer {
            id
            slice_type
            primary {
              padding_bottom
              padding_top
              background_color
            }
          }
          ... on PrismicLandingPageDataBodyTuto {
            id
            slice_type
            primary {
              padding_top
              padding_bottom
              background_color
            }
          }
          ... on PrismicLandingPageDataBodySlider {
            id
            slice_type
            primary {
              background_color
              padding_bottom
              padding_top
            }
            items {
              item_title
              link {
                url
                target
              }
              image {
                alt
                dimensions {
                  height
                  width
                }
                url
              }
            }
          }
          ... on PrismicLandingPageDataBodyReviews {
            id
            slice_type
            primary {
              background_color
              padding_bottom
              padding_top
            }
            items {
              item_title
              item_subtitle
              item_content {
                html
              }
              item_author
              link {
                url
                target
              }
              link_title
            }
          }
        }
      }
    }
    tutos: allPrismicTuto(
      filter: {
        lang: { glob: $cmsLocaleGlob }
        tags: { in: "tuto" }
        data: { thumbnail: { url: { ne: null } } }
      }
      sort: { fields: first_publication_date, order: DESC }
    ) {
      nodes {
        id
        url
        data {
          thumbnail {
            dimensions {
              height
              width
            }
            alt
            url
          }
        }
      }
    }
    products: allShopifyProduct(
      filter: { skus: { in: $fetchProducts }, locale: { eq: $locale } }
    ) {
      nodes {
        shopifyAdminId
        ...ProductItem
        priceRange {
          minVariantPrice {
            amount
            currencyCode
          }
        }
        options {
          name
          values
        }
      }
    }
  }
`;
export default LandingPageTemplate;
