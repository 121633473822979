import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';
import HtmlRenderer from '../../components/common/Html';
import MagicLink from '../../components/link';
// import { Imgix } from '../../utils';
import { device, Imgix } from '../../utils';

const Wrapper = styled.div`
  background-color: #fff;

  &.pt-small {
    padding-top: var(--padding-small);
  }

  &.pb-small {
    padding-bottom: var(--padding-small);
  }

  &.pt-large {
    padding-top: var(--padding-large);
  }

  &.pb-large {
    padding-bottom: var(--padding-large);
  }
`;

const Grid = styled.div`
  display: grid;
  background-color: #f2f3f5;

  @media ${device.tablet} {
    grid-template-columns: 32% 45% 23%;
    align-items: flex-end;

    &.reverse {
      grid-auto-flow: dense;
      direction: rtl;
    }
  }

  @media ${device.laptop} {
    grid-template-columns: 35% 40% 25%;
    align-items: center;
  }

  &.no-product {
    @media ${device.tablet} {
      grid-template-columns: 55% 45%;
    }
  }
`;

const Illustration = styled.div`
  height: 100%;

  @media ${device.tabletDown} {
    order: 3;
    border: 15px solid transparent;
    box-sizing: border-box;
  }

  .gatsby-image-wrapper {
    height: 100%;

    img {
      object-fit: cover;
    }
  }
`;

const Content = styled.div`
  padding: 30px 20px;
  direction: ltr;

  @media ${device.tabletDown} {
    order: 1;
  }

  .simple-link {
    display: inline-block;
    color: #fff;
    font-weight: bold;
    margin-top: 30px;
    font-size: 18px;
    padding: 10px 17px 10px 20px;

    &:after {
      margin-left: 5px;
    }

    @media ${device.tabletDown} {
      display: block;
      max-width: max-content;
      margin-left: auto;
      margin-right: auto;
    }
  }

  h2 {
    font-size: 24px;
    font-weight: bold;
    line-height: 1.2em;
    margin-top: 0;
  }

  p {
    font-size: 16px;
    line-height: 1.5em;
  }

  @media ${device.tablet} {
    padding: 30px;
  }

  @media ${device.laptop} {
    padding: 20px 40px;

    h2 {
      font-size: 30px;
    }
  }
`;

const Product = styled.div`
  @media ${device.tabletDown} {
    padding: 0 20px 20px 20px;
    order: 2;
  }

  @media ${device.laptop} {
    height: 100%;
  }

  .gatsby-image-wrapper {
    height: 100%;

    img {
      object-fit: contain !important;
      object-position: bottom center !important;
    }
  }
`;

const Featured = ({
  paddingTop,
  paddingBottom,
  containerColor,
  backgroundColor,
  textColor,
  reverseDesktop,
  imageLeft,
  sectionTitle,
  sectionContent,
  linkUrl,
  linkText,
  imageRight,
  imageRightMobile,
}) => {
  const isProduct = !!imageRight?.url;
  return (
    <Wrapper
      className={classNames(
        'container',
        `pt-${paddingTop}`,
        `pb-${paddingBottom}`
      )}
      style={{
        backgroundColor: containerColor,
      }}
    >
      <Grid
        style={{
          backgroundColor,
          color: textColor,
        }}
        className={classNames({
          reverse: reverseDesktop,
          'no-product': !isProduct,
        })}
      >
        <Illustration>
          <Imgix
            {...imageLeft}
          // sizes={imgResponsive}
          />
        </Illustration>
        <Content>
          {sectionTitle && <h2>{sectionTitle}</h2>}
          <HtmlRenderer html={sectionContent?.html} className="html-renderer" />
          {linkUrl && (
            <MagicLink className="simple-link block buy" url={linkUrl}>
              {linkText}
            </MagicLink>
          )}
        </Content>
        {isProduct && (
          <Product>
            {imageRightMobile?.url ? (
              <Imgix
                alt={imageRightMobile?.alt || imageRight?.alt}
                breakpoints={{
                  0: imageRightMobile,
                  760: imageRight,
                }}
              // sizes={imgResponsive}
              />
            ) : (
              <Imgix
                {...imageRight}
              // sizes={imgResponsive}
              />
            )}
          </Product>
        )}
      </Grid>
    </Wrapper>
  );
};

export default Featured;
