import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';
import MagicLink from '../../components/link';
import { device } from '../../utils';
import RatingStars from '../eshop/product/RatingStars';
// import { Imgix } from '../../utils';

const Wrapper = styled.div`
  background-color: #fff;

  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 30px;

  &.pt-small {
    padding-top: var(--padding-small);
  }

  &.pb-small {
    padding-bottom: var(--padding-small);
  }

  &.pt-large {
    padding-top: var(--padding-large);
  }

  &.pb-large {
    padding-bottom: var(--padding-large);
  }

  @media ${device.tablet} {
    grid-template-columns: 1fr 1fr 1fr;
  } 
`;

const Block = styled.div`
  display: flex;
  flex-direction: column;
  background: #F2F3F5;
  padding: 40px 20px;

  font-size: 17px;

  .title {
    position: relative;
    font-family: 'Montserrat';
    font-weight: 900;
    font-size: 20px;
    line-height: 1.3em;
    padding-bottom: 5px;
    margin-bottom: 20px;

    &:after {
      content:"";
      position: absolute;
      top: 100%;
      left: 0;
      width: 70px;
      border-top: 2px solid #000;
    }
  }
  .subtitle {
    font-weight: 700;
  }

  .rating {
    margin-top: 15px;
    margin-bottom: 10px;
  }

  .author {
    font-weight: bold;
    text-align: right;
  }

  a {
    padding-top: 20px;
    display: inline-block;
    margin-top: auto;
    max-width: max-content;
    font-weight: bold;
  }
`;

const Reviews = ({
  paddingTop,
  paddingBottom,
  backgroundColor = '#FFF',
  items = []
}) => {
  return (
    <Wrapper
      className={classNames(
        'container',
        `pt-${paddingTop}`,
        `pb-${paddingBottom}`
      )}
      style={{
        backgroundColor,
      }}
    >
      {items.map((item) => {
        console.log(item);
        return (
          <Block>
            {item.item_title && <div className="title">{item.item_title}</div>}
            {item.item_subtitle && <div className="subtitle">{item.item_subtitle}</div>}

            <div className="rating">
              <RatingStars rate={5} />
            </div>

            <div dangerouslySetInnerHTML={{ __html: item.item_content?.html }} />
            <div className="author">{item.item_author}</div>

            {item.link?.url && <MagicLink url={item.link?.url} className="simple-link red">{item.link_title}</MagicLink>}
          </Block>
        );
      })}
    </Wrapper>
  );
};

export default Reviews;
