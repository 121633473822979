import React from 'react';

import { Wrapper, Slices } from './index.style';

import Cover from './Cover';
import Content from './Content';
import Featured from './Featured';
import Spacer from './Spacer';
import Tuto from './Tuto';
import Slider from './Slider';
import Reviews from './Reviews';

// const imgResponsive = {
//   xxs: 11,
//   md: 5,
//   contained: true,
// };

const LandingScreen = ({ page }) => {
  return (
    <Wrapper>
      <Slices>
        {page?.body.map((slice, key) => {
          if (slice.slice_type === 'cover') {
            return (
              <Cover
                key={slice.id}
                mobile={slice.primary.mobile}
                desktop={slice.primary.desktop}
                eager={key === 0}
              />
            );
          } else if (slice.slice_type === 'contenu') {
            return (
              <Content
                key={slice.id}
                content={slice.primary.content}
                backgroundColor={slice.primary.background_color}
                textColor={slice.primary.text_color}
                paddingTop={slice.primary.padding_top}
                paddingBottom={slice.primary.padding_bottom}
                maxWidth={slice.primary.max_width || undefined}
              />
            );
          } else if (slice.slice_type === 'spacer') {
            return (
              <Spacer
                key={slice.id}
                backgroundColor={slice.primary.background_color}
                paddingTop={slice.primary.padding_top}
                paddingBottom={slice.primary.padding_bottom}
              />
            );
          } else if (slice.slice_type === 'feature') {
            return (
              <Featured
                key={slice.id}
                paddingTop={slice.primary.padding_top}
                paddingBottom={slice.primary.padding_bottom}
                containerColor={slice.primary.container_color}
                backgroundColor={slice.primary.background_color}
                textColor={slice.primary.text_color}
                reverseDesktop={slice.primary.reverse_desktop}
                imageLeft={slice.primary.image_left}
                sectionTitle={slice.primary.section_title}
                sectionContent={slice.primary.section_content}
                linkUrl={slice.primary.link?.url}
                linkText={slice.primary.link_title}
                imageRight={slice.primary.image_right}
                imageRightMobile={slice.primary.image_right_mobile}
              />
            );
          } else if (slice.slice_type === 'tuto') {
            return (
              <Tuto
                key={slice.id}
                items={slice.items}
                paddingTop={slice.primary.padding_top}
                paddingBottom={slice.primary.padding_bottom}
                backgroundColor={slice.primary.background_color}
              />
            );
          } else if (slice.slice_type === 'slider') {
            return (
              <Slider
                key={slice.id}
                items={slice.items.map(item => ({ ...item, title: item.item_title }))}
                paddingTop={slice.primary.padding_top}
                paddingBottom={slice.primary.padding_bottom}
                backgroundColor={slice.primary.background_color}
              />
            );
          } else if (slice.slice_type === 'reviews') {
            return (
              <Reviews
                key={slice.id}
                items={slice.items.map(item => ({ ...item, title: item.item_title }))}
                paddingTop={slice.primary.padding_top}
                paddingBottom={slice.primary.padding_bottom}
                backgroundColor={slice.primary.background_color}
              />
            );
          }

          return null;
        })}
      </Slices>
    </Wrapper>
  );
};

export default LandingScreen;
